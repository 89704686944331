#root {
  overflow-y: scroll;
  margin: 0 0 0 0;
  position: sticky;
  background-image: url('./assets/FooterBackground.svg');
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: #f5f8fb;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
