/* track not checked / slider background*/
.material.p-inputswitch .p-inputswitch-slider {
  background-color: #d9c3ff !important;
}
/* thumb not checked / slider input*/
.material.p-inputswitch .p-inputswitch-slider:before {
  background-color: #a776fd !important;
}
/* track checked / slider background*/
.material.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #bbe8cd !important;
}
/* thumb checked / slider input*/
.material.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #63d1b4 !important;
}
/* track not checked & disabled / slider background*/
.material.p-disabled.p-inputswitch .p-inputswitch-slider {
  background-color: #e9e9e9 !important;
}
/* thumb not checked & disabled / slider background*/
.material.p-disabled.p-inputswitch .p-inputswitch-slider:before {
  background-color: #e9e9e9 !important;
}
.material.p-disabled {
  opacity: 1 !important;
}
